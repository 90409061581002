@import "swiper/swiper-bundle.css";
@import './swiper';
@import './fonts';
@import './bootstrap';
@import './header';
@import './pages';
@import './buttons';

* {
    box-sizing: border-box;

    &:focus-visible {
        outline: none;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    background-color: #fff;
}

a {
    text-decoration: none;
    color: #333;
    transition: all .25s;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #E0E0E0;
    margin: 0;
    width: 13px;
    height: 13px;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-right: 10px;

    &:checked {
        background-color: #011C2A;
        border-color: #011C2A;

        &:before {
            content: "\F26E";
            font-family: "bootstrap-icons";
            color: #fff;
        }
    }
}

hr {
    margin: 64px 0;
    opacity: 1;
}

img {
    max-width: 100%;
    border-radius: 4px;
}

h1, h2, h3, h4, h5, h6 {
     font-weight: 400;
}

h1 {
    font-size: 40px;
   @media screen and (min-width: 576px){
    font-size: 48px;
   }
}
h2 {
    font-size: 28px;
    @media screen and (min-width: 576px){
        font-size: 36px;
       }
}
h3 {
    font-size: 22px;
    @media screen and (min-width: 576px){
        font-size: 24px;
       }
}
ol {
    ul {
        list-style-type:disc;
    }
}