.white-button {
    font-family: "Roboto Condensed", sans-serif;
    background-color: #fff;
    color: #333 !important;
    padding: 18px 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.10);
    .bi-dash-lg {
        transform: rotate(90deg);
        font-size: 24px;
        font-weight: 300;
    }
    .bi-plus-lg {
        font-size: 20px;
        font-weight: bold;
    }
    &:hover {
        background-color: #424242;
        color: #fff!important;
    }
}

.black-button {
    font-family: "Roboto Condensed", sans-serif;
    background-color: #011C2A;
    color: #fff;
    padding: 15px 48px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.2s;
    align-items: center;
    &:hover {
        background-color: #fff;
        color: #011C2A;
    }
}