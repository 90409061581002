.swiper-vertical{
    .swiper-pagination {
        opacity: .5;
        right: unset;
        left: 12px;
        top: calc(50% + 40px);
        @media screen and (min-width: 1024px) {
            left: 24px;
        }
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 10px 0;
            opacity: 1;
            background: #fff;
            &.swiper-pagination-bullet-active {
                background: #ff7223;
            }
        }
    }
}

.swiper-horizontal{
    .hswiper-pagination {
        opacity: .5;
        right: unset;
        left: 50%;
        bottom: 24px;
        position: absolute;
        width: unset;
        top: unset;
        z-index: 1;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            opacity: 1;
            background: #fff;
            &.swiper-pagination-bullet-active {
                background: #ff7223;
            }
        }
    }
}