@import "bootstrap/scss/functions.scss";
//----------------------------------------------------
$enable-negative-margins: true;

//spacers
$spacer: 16px; // 16px
$spacers: (
    0: 0,
    1: $spacer * .25,
    //4px
    2: $spacer * .5,
    //8px
    3: $spacer,
    //16px
    4: $spacer * 1.5,
    //24px
    5: $spacer * 2,
    //32px
    6: $spacer * 3,
    //48px
    7: $spacer * 3.5,
    //56px
    8: $spacer * 4,
    //64px
    9: $spacer * 5,
    //80px
    10: $spacer * 6.25,
    //100px
    11: $spacer * 8,
    //128px
);

@each $size, $value in $spacers {
    .mb-#{$size} {
        margin-bottom: $value;
    }
}

//fonts
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 700;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-normal;



@import "bootstrap/scss/variables";
//----------------------------------------------------
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
//----------------------------------------------------
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/transitions";
//----------------------------------------------------
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/nav";

//----------------------------------------------------
@import "bootstrap/scss/utilities/api";
$bootstrap-icons-font-dir: "../../../node_modules/bootstrap-icons/font/fonts";
@import "bootstrap-icons/font/bootstrap-icons.scss";

.bi {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.container-fluid {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px;
    z-index: 1;

    @media screen and (min-width: 1024px) {
        padding: 0 128px;
    }
}

//tabs
.nav-tabs {
    border-bottom: 1px solid #000;
    background-color: #FFF;

    .nav-item {
        width: 25%;

        .nav-link {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            border-radius: 0;
            width: 100%;
            padding: 10px 10px;
            border: none;
            @media screen and (min-width: 1024px ) {
                font-size: 24px;
                padding: 18px 10px;
            } 
                
            
            &.active {
                background: #FF7223;
                color: #FFF;
                font-weight: 900;
                border-bottom: 1px solid #000;
                &:hover {
                    color: #FFF;
                }
            }

            &:hover {
                isolation: isolate;
                border-color: transparent;
                color: #FF7223;
            }
            &:focus {
                isolation: isolate;
                border-color: transparent;
            }
        }
    }
}
.tab-content {}