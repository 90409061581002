#top-header {
    padding: 0 0;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    user-select: none;
    background-color: rgba(56, 56, 56, 0.75);
    color: #fff;

    .logo {
        height: 38px;
    }

    a {
        color: #fff;
    }

    .navbar-toggler {
        color: #fff;
        border-color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:focus {
            box-shadow: none;
        }

        @media screen and (min-width: 992px) {
            display: none;
        }
    }

    .offcanvas {
        background-color: #424242;

        .offcanvas-header {
            padding: 24px 24px;
        }

        .offcanvas-body {

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                gap: 26px;

                li {
                    padding: 0 4px;
                    position: relative;

                    a {
                        display: block;
                        font-size: 24px;
                        line-height: 72px;
                        @media screen and (min-width: 992px) {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }

                    &.active {
                        font-weight: 900;

                        &:after {
                            content: '';
                            display: none;
                            width: 100%;
                            height: 2px;
                            background-color: #FFF;
                            position: absolute;
                            left: 0;
                            bottom: -11px;
                            @media screen and (min-width: 992px) {
                                display: block;
                            }
                        }

                        a {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

    }
}

.header {
    height: 275px;
    color: #fff;

    @media screen and (min-width: 768px) {
        height: 365px;
    }
}

.individual {
    padding-top: 80px;

    .header {
        background-image: url('../img/individual-header.webp');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.individual-pitfire {
    padding-top: 80px;

    .header {
        background-image: url('../img/header-pitfire.webp');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.individual-adventure {
    padding-top: 80px;

    .header {
        background-image: url('../img/header-adventure.webp');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.training {
    padding-top: 80px;

    .header {
        background-image: url('../img/header-training.webp');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.training-storrytelling {
    padding-top: 80px;

    .header {
        background-image: url('../img/header-storytelling.webp');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.training-pitch {
    padding-top: 80px;

    .header {
        background-image: url('../img/header-storytelling.webp');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: cover;
    }
}