.vswiper {
    .swiper-wrapper{
        position: relative;
        height: 100vh;
        section {
            .page {
                height: 100%;
                width: 100%;
                background-size: cover;
                position: relative;
                padding-top: 104px;
                color: #fff;
                padding-left: 10px;
                @media screen and (min-width: 992px) {
                    padding-left: 0;
                    
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    z-index: 0;
                }
    
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 0;
                }
                a {
                    color: #fff;
                }
                .scroll {
                    font-size: 40px;
                    line-height: 0px;
                    cursor: pointer;
                    position: absolute;
                    bottom: 24px;
                    left: calc(50% - 20px);
                    z-index: 4;
                }
            }
    
            &:nth-child(1) {
                .page {
                    background-image: url('../img/slide1.webp');
                    background-position-x: right;
                    background-position-y: center;
    
                    &:after {
                        background-image: url('../img/wolf-1.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: top left;
    
                    }
                }
            }
    
            &:nth-child(2) {
                .page {
                    background-image: url('../img/slide2.webp');
                    background-position-x: right;
                    background-position-y: center;
    
                    &:before {
                        background-color: rgba(0, 0, 0, 0.50);
                    }
    
                    &:after {
                        background-image: url('../img/wolf-2.png');
                        background-repeat: no-repeat;
                        background-position: bottom right;
                    }
                    
                }
                .page2_2 {
                    background-image: url('../img/slide2_2.webp');
                    background-position-x: center;
                    background-position-y: center;
    
                    &:before {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
                    &:after {
                        background-image: none;
                    }
                }
            }
    
            &:nth-child(3) {
                .page {
                    background-image: url('../img/slide3.webp');
                    background-position-x: center;
                    background-position-y: bottom;
    
                    &:before {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
    
                    &:after {
                        background-image: url('../img/wolf-3.png');
                        background-repeat: no-repeat;
                        background-position: top left;
                    }
                }
            }
    
            &:nth-child(4) {
                .page {
                    background-image: url('../img/slide4.webp');
                    background-position-x: right;
                    background-position-y: top;
    
                    &:before {
                        background-color: rgba(0, 0, 0, 0.50);
                    }
    
                    &:after {
                        background-image: url('../img/wolf-4.png');
                        background-repeat: no-repeat;
                        background-position: bottom right;
                    }
                }
            }
    
            &:nth-child(5) {
                .page {
                    background-image: url('../img/slide5.webp');
                    background-position-x: left;
                    background-position-y: center;
    
                    &:before {
                        background-color: rgba(0, 0, 0, 0.50);
                    }
    
                    &:after {
                        background-image: url('../img/wolf-4.png');
                        background-repeat: no-repeat;
                        background-position: bottom right;
                    }
                }
            }
    
        }
    }
    
}



.form-mail {
    background-color: #fff;
    padding: 30px 35px;
    color: #011C2A;
    font-size: 12px;
    .title {
        font-size: 14px!important;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
    }
    label {
        display: block;
        font-weight: 500;
        
    }

    input[type=text] {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #8D8D8D;
        color: #8D8D8D;
        padding: 12px 0;
        font-size: 14px;
        font-weight: 500;
    }
    .subject {
        label {
            display: inline-flex;
            align-items: center;
            font-weight: 400;
            cursor: pointer;
        }
    }
}
.grey-block {
    background-color: #4E4E4E;
    color: #fff;
    font-size: 16px;
    padding: 40px 20px;
    max-width: 360px;
    border-radius: 4px;
    text-align: left;
   
}
.individual {
    .card {
        img {
            width: 100%;
            height: auto;
            border-radius: 4px;
        }
    }
}
.individual-pitfire {
.journey {
    img {
        width: 300px;
    }
}
}